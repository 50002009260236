import {
  ChakraProvider,
  Box,
  VStack,
  Grid,
  theme,
  Icon,
  Button,
  Stack,
  Text,
  Textarea,
  ScaleFade,
  SimpleGrid,
  Center,
  Container,
  Divider,
  Input,
  FormControl,
  FormLabel,
  Select,
  useToast,
} from "@chakra-ui/react"
import { ColorModeSwitcher } from "./ColorModeSwitcher"
import { FaCaravan, FaCarSide, FaEnvelope, FaHippo, FaHouseUser, FaPlane, FaSave, FaUmbrellaBeach, FaUser, FaUserFriends } from "react-icons/fa"
import { useState } from "react";

const FIRST_QUESTION = 'stay-or-vay';
const LAST_QUESTION = 'anything-missed';

const saveToLocalStorage = (name: string, answers: Record<string, string>) => {
  localStorage.setItem(`momcation--${name}`, JSON.stringify(answers));
};

const loadFromLocalStorage = (name: string): Record<string, string> => {
  const payload = localStorage.getItem(`momcation--${name}`);
  // Only works because we only load keys we know match this app.
  return JSON.parse(payload as string);
}

const PossibleAnswer = ({ children, color, icon, msg, onClick }: any) => (
  <Box backgroundColor={color} border='1px solid' borderColor={color || 'gray.300'} borderRadius='.35em' minWidth='315px' onClick={onClick} padding='.5em'>
    { icon ? <Icon as={icon} boxSize={16} /> : <Text as='h3' fontSize='4xl'>{msg}</Text> }<br />
    {children}
  </Box>
);

const StayOrVay = ({ onAnswer, selectedAnswer, }: any) => (
  <VStack>
    <h1>
      Staycation or Vacation?
    </h1>

    <Stack direction={['column', 'row']} >
      <PossibleAnswer color={selectedAnswer === 'stay' && 'green.300'} icon={FaHouseUser} onClick={() => onAnswer('stay')}>
        Stay
      </PossibleAnswer>
      <PossibleAnswer color={selectedAnswer === 'vay' && 'green.300'} icon={FaUmbrellaBeach} onClick={() => onAnswer('vay')}>
        Vay
      </PossibleAnswer>
    </Stack>
  </VStack>
);

const HowFarToGo = ({ onAnswer, selectedAnswer, }: any) => (
  <VStack>
    <h1>
      How far away from home would you like to go?
    </h1>

    <Stack direction={['column', 'row']} >
      <PossibleAnswer color={selectedAnswer === '1' && 'green.300'} icon={FaCarSide} onClick={() => onAnswer('1')}>
        Not Far (about an hour or so)
      </PossibleAnswer>
      <PossibleAnswer color={selectedAnswer === '2' && 'green.300'} icon={FaCaravan} onClick={() => onAnswer('2')}>
        Kinda far (about a three hour drive)
      </PossibleAnswer>
      <PossibleAnswer color={selectedAnswer === '3' && 'green.300'} icon={FaPlane} onClick={() => onAnswer('3')}>
        Really far (less than 6 hours flight)
      </PossibleAnswer>
    </Stack>
  </VStack>
);

const HowLongToGo = ({ onAnswer, selectedAnswer }: any) => (
  <VStack>
    <h1>
      How long?
    </h1>

    <Stack direction={['column', 'row']} >
      <PossibleAnswer color={selectedAnswer === '1' && 'green.300'} msg='1' onClick={() => onAnswer('1')}>Night</PossibleAnswer>
      <PossibleAnswer color={selectedAnswer === '2' && 'green.300'} msg='2' onClick={() => onAnswer('2')}>Nights</PossibleAnswer>
      <PossibleAnswer color={selectedAnswer === '3' && 'green.300'} msg='3' onClick={() => onAnswer('3')}>Nights</PossibleAnswer>
    </Stack>
  </VStack>
);

const WithFriendsOrSolo = ({ onAnswer, selectedAnswer }: any) => (
  <VStack>
    <h1>
      Want others to join you? Go it solo?
    </h1>

    <Stack direction={['column', 'row']} >
      <PossibleAnswer color={selectedAnswer === 'go-solo' && 'green.300'} icon={FaUser} onClick={() => onAnswer('go-solo')}>On My Own!</PossibleAnswer>
      <PossibleAnswer color={selectedAnswer === 'with-friends' && 'green.300'} icon={FaUserFriends} onClick={() => onAnswer('with-friends')}>With Friends!</PossibleAnswer>
      <PossibleAnswer color={selectedAnswer === 'a-bit-of-both' && 'green.300'} icon={FaHippo} onClick={() => onAnswer('a-bit-of-both')}>A Bit Of Both!</PossibleAnswer>
    </Stack>
  </VStack>
);

const SpecialFoodRequests = ({ onAnswer, selectedAnswer }: any) => (
  <VStack>
    <Text as='h2' fontSize='2xl'>
      Are there any special meals you would like?
    </Text>

    <Textarea rows={10} onChange={e => onAnswer(e.target.value)} placeholder='Ex: I will only eat sushi this trip, only meat, etc.' resize='vertical' size='lg' value={selectedAnswer} />
  </VStack>
);

const AnythingMissed = ({ onAnswer, selectedAnswer }: any) => (
  <VStack>
    <Text as='h2' fontSize='2xl'>
      What else would help make your momcation spectacular?
    </Text>

    <Textarea rows={10} onChange={e => onAnswer(e.target.value)} placeholder='Ex: Craft supplies, another special arrangement, etc.' resize='vertical' size='lg' value={selectedAnswer} />
  </VStack>
);

const QuestionToShow = ({ onFinish }: { onFinish: (answers: Record<string, string>) => void }) => {
  const [question, setQuestion] = useState('stay-or-vay');
  const [answers, setAnswers] = useState<Record<string, string>>({});
  const [transitioning, setTransitioning] = useState(true);

  const updateAnswers = (k: string) => (v: string) => {
    setAnswers({
      ...answers,
      [k]: v
    });
  };

  return (
    <VStack>
      {
        question === FIRST_QUESTION ? (
          <ScaleFade in={transitioning}>
            <Text fontSize="xl">🏖️ Let's get started! 🏖️</Text>
          </ScaleFade>
        ) : null
      }
      <>
        <ScaleFade in={transitioning}>
          {
            question === 'stay-or-vay' ? <StayOrVay onAnswer={updateAnswers('stay-or-vay')} selectedAnswer={answers['stay-or-vay']} /> :
            question === 'how-far-to-go' ? <HowFarToGo onAnswer={updateAnswers('how-far-to-go')} selectedAnswer={answers['how-far-to-go']} /> :
            question === 'how-long-to-go' ? <HowLongToGo onAnswer={updateAnswers('how-long-to-go')} selectedAnswer={answers['how-long-to-go']} /> :
            question === 'with-friends-or-solo' ? <WithFriendsOrSolo onAnswer={updateAnswers('with-friends-or-solo')} selectedAnswer={answers['with-friends-or-solo']} /> :
            question === 'special-food-requests' ? <SpecialFoodRequests onAnswer={updateAnswers('special-food-requests')} selectedAnswer={answers['special-food-requests']} /> :
            question === 'anything-missed' ? <AnythingMissed onAnswer={updateAnswers('anything-missed')} selectedAnswer={answers['anything-missed']} /> :
            null
          }
        </ScaleFade>
        <Stack direction={['row', 'column']}>
          {
            question !== FIRST_QUESTION && 
              <Button
                onClick={() => {
                  setTransitioning(false);
                  setTimeout(() => setTransitioning(true), 125);
                  const nextQuestion = 
                    question === 'how-far-to-go' ? 'stay-or-vay' :
                    question === 'how-long-to-go' ? (answers['stay-or-vay'] === 'stay' ? 'stay-or-vay' : 'how-far-to-go') :
                    question === 'with-friends-or-solo' ? 'how-long-to-go' :
                    question === 'special-food-requests' ? 'with-friends-or-solo' :
                    question === 'anything-missed' ? 'special-food-requests' :
                    '';

                  setQuestion(nextQuestion);
                }}
              >
                Previous
              </Button>
          }

          {
            question !== LAST_QUESTION && 
              <Button
                onClick={() => {
                  setTransitioning(false);
                  setTimeout(() => setTransitioning(true), 125);
                  const nextQuestion = 
                    question === 'stay-or-vay' ? (answers['stay-or-vay'] === 'vay' ? 'how-far-to-go' : 'how-long-to-go') :
                    question === 'how-far-to-go' ? 'how-long-to-go' :
                    question === 'how-long-to-go' ? 'with-friends-or-solo' : 
                    question === 'with-friends-or-solo' ? 'special-food-requests' : 
                    question === 'special-food-requests' ? 'anything-missed' : 
                    '';

                  setQuestion(nextQuestion);
                }}
              >
                Next
              </Button>
          }

          {
            question === LAST_QUESTION && 
              <Button
                onClick={() => {
                  onFinish(answers)
                }}
              >
                Finish
              </Button>
          }
        </Stack>
      </>
    </VStack>
  );
};

export const ShowResults = ({ initialName, answers }: { initialName?: string, answers: Record<string, string> }) => {
  const [name, setName] = useState<string>(initialName || '');
  const toast = useToast();

  return (
    <>
      <FormControl maxW="95%" margin='1.5em 0'>
        <FormLabel htmlFor='momcationName' fontWeight='bold'>Name this idea:</FormLabel>
        <Input id='momcationName' onChange={e => setName(e.target.value)} placeholder='Ex: Island getaway, Lake Sanctuary, etc.' _placeholder={{ color: 'gray.500', opacity: .75, }} value={name} />
      </FormControl>

      <SimpleGrid columns={2} gap={8}>
        <Center>Staycation or Vacation?</Center>
        <Container maxW='xl' centerContent>
          <Icon as={answers['stay-or-vay'] === 'stay' ? FaHouseUser : FaUmbrellaBeach} boxSize={8} marginRight='.25em' />
          {`${answers['stay-or-vay'][0].toUpperCase()}${answers['stay-or-vay'].slice(1).toLowerCase()}`}
        </Container>

        <Center>How far away to go?</Center>
        <Container maxW='xl' centerContent>
          <Icon as={answers['how-far-to-go'] === '1' ? FaCarSide : answers['how-far-to-go'] === '2' ? FaCaravan : FaPlane} boxSize={8} marginRight='.25em' />
          {
            answers['how-far-to-go'] === '1' ? 'About 1 hour' :
            answers['how-far-to-go'] === '2' ? 'About 3 hours' :
            'Really far!'
          }
        </Container>

        <Center>How long should it be?</Center>
        <Container maxW='xl' centerContent>
          <Text as='h3' fontSize='2xl'>{ answers['how-long-to-go'] }</Text> 
          {
            answers['how-long-to-go'] === '1' ? 'Day' :
            'Days'
          }
        </Container>

        <Center>With Friends or Solo?</Center>
        <Container maxW='xl' centerContent>
          <Icon as={answers['with-friends-or-solo'] === 'go-solo' ? FaUser : answers['with-friends-or-solo'] === 'with-friends' ? FaUserFriends : FaHippo} boxSize={8} marginRight='.25em' />
          {
            answers['with-friends-or-solo'] === 'go-solo' ? 'Solo' :
            answers['with-friends-or-solo'] === 'with-friends' ? 'Friends' :
            'Both'
          }
        </Container>

        <Center>With Friends or Solo?</Center>
        <Container maxW='xl' centerContent>
          <Icon as={answers['with-friends-or-solo'] === 'go-solo' ? FaUser : answers['with-friends-or-solo'] === 'with-friends' ? FaUserFriends : FaHippo} boxSize={8} marginRight='.25em' />
          {
            answers['with-friends-or-solo'] === 'go-solo' ? 'Solo' :
            answers['with-friends-or-solo'] === 'with-friends' ? 'Friends' :
            'Both'
          }
        </Container>
        
        <Center>🍔 Special Meals? 🍔</Center>
        <Container maxW='lg' centerContent>
          <Text as='h3' fontSize='.8em'>{ answers['special-food-requests'] }</Text> 
        </Container>

        <Center>✨ What other magic? ✨</Center>
        <Container maxW='lg' centerContent>
          <Text as='h3' fontSize='.8em'>{ answers['anything-missed'] }</Text> 
        </Container>
      </SimpleGrid>

      <Divider paddingTop='.5em' />

      <Box padding='1em 0'>
        <Text as='h3' fontSize='md' fontStyle='italic'>
          Want this fantasy to become reality? Hit "Send to planner", print to PDF,
          then email it to them!
        </Text>
      </Box>
      
      <SimpleGrid columns={2}>
        <Button colorScheme='green' onClick={() => window.print()} rightIcon={<FaEnvelope />}>
          Send to planner
        </Button>

        <Button
          rightIcon={<FaSave />}
          onClick={() => { saveToLocalStorage(name, answers); toast({ position: 'top', title: 'Idea saved!', status: 'success', duration: 2500, isClosable: true }); }}
          variant='solid'
        >
          Save for later
        </Button>
      </SimpleGrid>
    </>
  )
}

export const MainFlow = () => {
  const previousIdeas = Object.keys(localStorage).filter(k => k.startsWith('momcation--')).map(k => k.replace('momcation--', ''));
  const [stage, setStage] = useState(
    previousIdeas.length > 0 ? 'return-stage' : 'questions-to-show'
  );
  const [answers, setAnswers] = useState<Record<string, string>>({});
  const [initialName, setInitialName] = useState('');

  return (
    stage === 'return-stage' ? (
      <>
        <Text fontSize='3xl'>Welcome back!</Text>
        <Text fontSize='lg'>
          Would you like to start a new vaca idea, or look at older ideas?
        </Text>

        <VStack>
          <Button colorScheme='green' onClick={() => setStage('questions-to-show')}>Start New Idea</Button>
          
          <FormControl  maxW='65%' >
            <Select onChange={e => { setInitialName(e.target.value); setAnswers(loadFromLocalStorage(e.target.value)); setStage('show-results'); }} placeholder='View Previous Idea'>
              {
                previousIdeas.map(name => <option value={name}>{name}</option>)
              }
            </Select>
          </FormControl>
        </VStack>
      </>
    ) :
    stage === 'questions-to-show' ? (
      <QuestionToShow onFinish={a => { setAnswers(a); setStage('show-results'); }} />
    ) :
    stage === 'show-results' ? (
      <ShowResults answers={answers} initialName={initialName} />
    ) : null
  )
};

export const App = () => (
  <ChakraProvider theme={theme}>
    <Box textAlign="center" fontSize="xl">
      <Grid minH="100vh" p={3}>
        <ColorModeSwitcher justifySelf="flex-end" />
        <Text as='h1' fontSize='5xl'>Momcation</Text>
        <MainFlow />
      </Grid>
    </Box>
  </ChakraProvider>
)
